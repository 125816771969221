body {
  background-color: #f5f5f5 !important;
}

a {
  text-decoration: none;
}

form h3.mantine-Title-root {
  margin-bottom: 8px;
  margin-top: 20px;
}

/* .link {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #40404b;
  cursor: pointer;
} */
